import {
    IonButtons,
    IonCard, IonCardContent,
    IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import { useParams } from 'react-router';
// import ExploreContainer from '../components/ExploreContainer';
import Layout from "../components/Layout";
import packageJson from '../../package.json'

const getContent = () =>
    <div className="ion-padding">
        <div>
            <p className="ion-margin-top">
                Good System App version {packageJson.version}
            </p>
        </div>
    </div>

const About: React.FC = () => {
  return (
        <Layout name="about">
          {getContent()}
        </Layout>
  );
};

export default About;
