/*
import Footer from '../components/Footers/Footer';
import HeaderStats from '../components/Headers/HeaderStats';
import Navbar from '../components/Navbars/Navbar';
import {
  AuthSecurity,
  ReactAuthenticationService,
} from '@gvp-microapps/gvp-services/authentication/react';
import { environment } from '../../environments/environment';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../views/authenticated/Dashboard';
import PageNotFound from '../views/authenticated/PageNotFound';
import Sidebar from '../components/Sidebar/Sidebar';
import { useSelector } from 'react-redux';

import Modal from '../components/Modal/Modal';
import Settings from "../views/authenticated/Settings";
*/
import { Route, Switch } from 'react-router-dom';
import Home from "../views/Home";
import Me from "../views/Me";
// const authService = new ReactAuthenticationService(environment.oktaConfig);

export default function Authenticated() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // const { show } = useSelector((state) => state.modal);

  return (
    /*<AuthSecurity authService={authService}>
      <Modal />
      <div className="bg-blueGray-100">
        <Navbar />
        {/!* <HeaderStats /> *!/}
        <div className="mx-auto w-full ">
          {/!* <Sidebar /> *!/}
          <Switch>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/settings" component={Settings} />
            <Route path="/auth/404" component={PageNotFound} />
          </Switch>
          <Footer />
        </div>
      </div>
    </AuthSecurity>*/
      <>
        <Route path="/" component={Me} />
      </>
  );
}
