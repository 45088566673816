import {useParams} from "react-router";
import {IonButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar} from "@ionic/react";

interface LayoutProps {
    name: string,
    nameDisplay?: string
}

const getTitle = (props: LayoutProps) => {
    // TODO localization
    return props.nameDisplay || props.name.replace('-', ' ')
}

const Layout: React.FC<LayoutProps> = ({ name, nameDisplay, children}) => {
    const title = getTitle({name, nameDisplay})

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle class="ion-text-uppercase">{title}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">{title}1</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <div>
                {/* children is the component occupying the "slot" in parent component */}
                {children}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Layout;
