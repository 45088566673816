// import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import Layout from "../components/Layout";

const Me: React.FC = () => {
  return (
        <Layout name="me" />
  );
};

export default Me;
