// import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import Layout from "../components/Layout";

const getContent = () => {
    // TODO localization
  return 'Community Directories'
}

const Directories: React.FC = () => {
  return (
      <Layout name="directories">
        {getContent()}
      </Layout>
  );
};

export default Directories;
