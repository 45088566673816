import {
    IonButtons,
    IonCard, IonCardContent,
    IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';

import '../pages/Page.css';
import Layout from "../components/Layout";

// TODO localization
const getContent = () =>
    <>
        <IonCard>
            <IonCardHeader>
                <IonCardSubtitle>Support Community Organizations</IonCardSubtitle>
                <IonCardTitle>Build Our Community</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                Donate and volunteer. Build a stronger community together!
            </IonCardContent>
        </IonCard>
        <IonCard>
            <IonCardHeader>
                <IonCardSubtitle>Get Your Favorites + Great Deals</IonCardSubtitle>
                <IonCardTitle>Buy Local</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                Find local businesses.
            </IonCardContent>
        </IonCard>

        <IonCard>
            <IonCardContent>
                <a href="/gs.apk" target="_blank">Download Good System App (Android)</a>
            </IonCardContent>
        </IonCard>
    </>

const Home: React.FC = () =>
    <Layout name="home">
        {getContent()}
    </Layout>

export default Home;
