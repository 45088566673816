// import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import Layout from "../components/Layout";

const getContent = () => {
    // TODO localization
  return 'Community Calendars'
}

const Calendars: React.FC = () => {
  return (
      <Layout name="calendars">
        {getContent()}
      </Layout>
  );
};

export default Calendars;
