/*
import Navbar from '../components/Navbars/NavbarPublic';
import FooterSmall from '../components/Footers/FooterPublic';
*/
import { Switch, Route, Redirect } from 'react-router-dom';
import Me from "../views/Me";
import Home from "../views/Home";
import Tools from "../views/Tools";
import About from "../views/About";
import Directories from "../views/Directories";
import Calendars from "../views/Calenders";
/*import Login from '../views/public/Login';
import Team from '../views/public/Team';
import PageNotFound from '../views/public/PageNotFound';
import About from '../views/public/About';*/

export default function Public() {
  return (
    <>
{/*      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage: 'url(/assets/img/register_bg_2.png)',
            }}
          ></div>

          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/about" exact component={About} />
            <Route path="/team" exact component={Team} />
            <Route path="/404" exact component={PageNotFound} />
          </Switch>
          <FooterSmall absolute />
        </section>
      </main>*/}
        <Route path="/" exact component={Home} />
        <Route path="/directories" exact component={Directories} />
        <Route path="/calendars" exact component={Calendars} />
        <Route path="/about" exact component={About} />
        {/*<Route path="/about" exact component={About} />
        <Route path="/404" exact component={PageNotFound} />*/}
    </>
  );
}
